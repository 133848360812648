import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppBenelux = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Red onze toekomst! Breng je stem uit in de Europese Verkiezingen – zo doe je het"
      description="Klik de link en stuur een bericht naar je vrienden via WhatsApp - geef hen een stem!"
      shareImage="benelux"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey!
Ben je een burger van een lidstaat van de EU en woon je in de UK?
Dan heb je stemrecht in de Europese Verkiezingen in de UK!

**BELANGRIJK!!!
💥 Stuur deze boodschap naar al je Belgische en Nederlandse vrienden in de UK – dit is ook onze thuis!💥
Klik hier: https://saveourfuture.uk/benelux

Registreer om te kunnen stemmen, volg de eenvoudige stappen hier (3 min werk):
https://saveourfuture.uk/simple

Doe het voor 7 mei (doe het nu!)
(je kan ook kiezen om per post te stemmen – “a postal vote” – dan stem je wanneer je wil)

**BELANGRIJK!!!
Stuur deze boodschap naar je Belgische en Nederlandse vrienden in de UK – dit is onze thuis!

Klik hier:
https://saveourfuture.uk/benelux

*DEZE KEER TELT ONZE STEM!!!* `}
    />
    <Interstitial>
      <p>Kies vijf beste vrienden of groepen om je WhatsApp-bericht te verzenden</p>
      <p>Bericht nu genereren</p>
      <p>U kunt het bericht personaliseren in de volgende stap ...</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppBenelux.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppBenelux;

export const query = graphql`
  query BeneluxQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
